jQuery(document).ready(function ($) {
    $('.option-domain-form').on('submit', function () {
        ajax_update_domain();
        return false;
    });

    $('.form-domain-delete').on('submit',function () {
        deleteDomainFromList();
        return false;
    });

    $('.option-mail-form').on('submit', function () {
        ajax_update_mail();
        return false;
    });

    $('.form-mail-delete').on('submit',function () {
        deleteMailFromList();
        return false;
    });

    function ajax_update_domain(){
        var mail = $('.option-domain-form #domaine_autorise').val();
        var ecole = $('.option-domain-form #ecole_domain').val();
        $.ajax({
            type   : "POST",
            url    : SMAjax.ajaxurl,
            data   : {
                action : 'add_domain_to_option',
                data : mail,
                ecole : ecole
            },
            success: function(data){

                json = JSON.parse(data);
                if(json.error == true){
                    $('.message-domain').html('<p class = "alert-domain">' + json.error_message + '</p>')
                }else{
                    var domain_formated = json.new_domain.mail.replace(/\./g,'-');
                    $('.message-domain').html('<p class = "save-domain">Modifications sauvegardées</p>');
                    $('.table-results tbody').append(
                        '<tr class = li-' + json.new_domain.mail.replace(/\./g,'-') + '>' +
                        '<td style="padding:10px;">' +
                        '<input name = "checked-domain" id = "' + domain_formated + '"type = "checkbox" value ="' + json.new_domain.mail + '"/></td>'+
                        '<td style="padding:10px;">' + json.new_domain.mail + '</label></td>' +
                        '<td style="padding:10px;"><span>' + json.new_domain.ecole + '</span></td></tr>'
                    );
                    console.log(json);
                }
            },

            error: function (xhr, ajaxOptions, thrownError, data) {
                console.log(data);
                console.log(xhr.status);
                console.log(thrownError);
            }
        });
    }

    function deleteDomainFromList() {

        var check = [];
        $.each($("input[name='checked-domain']:checked"), function(){
            var mail = ($(this).val());
            var ecole = ($(this).closest('tr').find('span').text());
            check.push({
                'mail':mail,
                'ecole':ecole,
            })
        });


        $.ajax({
            type: 'POST',
            url: SMAjax.ajaxurl,
            data: {
                action: 'ajax_delete_domain',
                data : check
            },
            success: function(data) {
                $.each(check, function (index, value) {

                    // valueFormated = value.split('.').join('');
                    // valueFormated = valueFormated.split('@').join('');
                    // value.replace(/\./g,'-');
                    // value.replace(/@/g,'');
                    console.log(value.mail);
                    $("input[value = '"+value.mail+"']").parent().parent().remove();
                });
                // location.reload();
            },

            error: function(err) {
                console.log("AJAX error in request: " + JSON.stringify(err, null, 2));
            }
        });
    }

    function ajax_update_mail(){
        var mail = $('.option-mail-form #mail_autorise').val();
        var ecole = $('.option-mail-form #mail_ecole').val();
        var checked = $('#concours_pass').is(':checked');
        $.ajax({
            type   : "POST",
            url    : SMAjax.ajaxurl,
            data   : {
                action : 'add_mail_to_option',
                data : mail,
                check : checked,
                ecole : ecole,
            },
            success: function(data){

                json = JSON.parse(data);
                if(json.error == true){
                    $('.message-mail').html('<p class = "alert-mail">' + json.error_message + '</p>')
                }else{
                    var mail_checked = '';
                    var domain_formated = json.new_mail.mail.replace(/\./g,'-');
                    if(json.new_mail.check == 'true'){
                        mail_checked = 'Oui';
                    }else{
                        mail_checked = 'Non';
                    }
                    $('.message-domain').html('<p class = "save-mail">Modifications sauvegardées</p>');
                    $('.table-results tbody').append(
                        '<tr class = li-' + json.new_mail.mail.replace(/\./g,'-') + '>' +
                        '<td style="padding:10px;">' +
                        '<input name = "checked-mail" id = "' + domain_formated + '"type = "checkbox" value ="' + json.new_mail.mail + '"/></td>'+
                        '<td style="padding:10px;">' + json.new_mail.mail + '</label></td>' +
                        '<td style="padding:10px;"><span>' + json.new_mail.ecole + '</span></td>' +
                        '<td style="padding:10px;"><span>' + mail_checked + '</span></td></tr>'
                    );
                }
            },

            error: function (xhr, ajaxOptions, thrownError, data) {
                console.log(data);
                console.log(xhr.status);
                console.log(thrownError);
            }
        });
    }

    function deleteMailFromList() {

        var check = [];
        $.each($("input[name='checked-mail']:checked"), function(){
            var mail = ($(this).val());
            var checked = ($(this).closest('tr').find('span').text());
            check.push({
                'mail':mail,
                'checked':checked,
            })
        });

        $.ajax({
            type: 'POST',
            url: SMAjax.ajaxurl,
            data: {
                action: 'ajax_delete_mail',
                data : check
            },
            success: function(data) {

                $.each(check, function (index, value) {
                    $("input[value = '"+value.mail+"']").parent().parent().remove();
                });
            },

            error: function(err) {
                console.log("AJAX error in request: " + JSON.stringify(err, null, 2));
            }
        });
    }

    $('#select-all').on('click', function () {
        if($('#select-all').is(':checked')){
            $('.table-results tbody input').prop('checked', true);
        }else{
            $('.table-results tbody input').prop('checked', false);
        }
    });
});


