jQuery( document ).ready(function($) {

    $('.form-mail').on('submit', function () {
        ajaxSubmit();
        return false;
    });

    function ajaxSubmit() {
        var formMail = jQuery('.form-mail').serialize();
        // var form_data = jQuery( this ).serializeArray();
        $.ajax({
            type: "POST",
            url: SMAjax.ajaxurl,
            data: formMail,
            success: function (data) {
                json = JSON.parse(data);

                if (json.errors == true) {
                    $('.form-response').html('<p class = alert-concours>' + json.errors_message + '</p>');
                } else {
                    $('.check-email').css('display', 'none');
                    $('.formulaire-concours').css('display', 'block');
                    delayValidation(json.mail);
                }
            },

            error: function (xhr, ajaxOptions, thrownError, data) {
                console.log(data);
                console.log(xhr.status);
                console.log(thrownError);
            }
        });
    }

    function delayValidation(mail){
        var delay = 30000;

        setTimeout(function(){
            $.ajax({
                url    : SMAjax.ajaxurl,
                data : {
                    action : 'validate_participation',
                    email : mail
                },
                success: function(data){
                    json = JSON.parse(data);
                   console.log(json.message);
                },
                error: function (xhr, ajaxOptions, thrownError, data) {
                    console.log(data);
                    console.log(xhr.status);
                    console.log(thrownError);
                }
            });
        }, delay);
    }
});